<template>
  <b-card
    no-body
    class="p-1"
  >
    <PartEditTabLog :part-id="$router.currentRoute.params.parteId" />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import PartEditTabLog from '../parts-edit/PartEditTabLog.vue'

export default {
  components: {
    PartEditTabLog,
    BCard,
  },
}
</script>

<style>

</style>
